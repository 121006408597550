import {
  Box,
  Collapse,
  Divider,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Portal,
  Stack,
  Text,
} from '@chakra-ui/react';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  MdExpandMore as ArrowDownIcon,
  MdExpandLess as ArrowUpIcon,
  MdClose as CloseIcon,
  MdSearch as SearchIcon,
} from 'react-icons/md';

import { OptionItem, OptionValue } from '@/common/types';
import useTranslation from '@/utils/i18n/useTranslation';
import { useScreenInfos } from '@/utils/mobiles/useScreenInfos';
import { createParentOptionsHashMap } from '@/utils/molecules/multiLayer';
import ClosableModal from '../ClosableModal';
import MultipleLayerSearchOption from './MultipleLayerSearchOption';
import MultipleLayerSelectOption from './MultipleLayerSelectOption';

type MultipleLayerSelectProps = {
  placeholder?: string;
  options: OptionItem[];
  value?: OptionValue;
  onChange: (value: OptionValue | undefined) => void;
  isClearable?: boolean;
};

export type HashArgs = {
  [id: string | number]: OptionItem;
};

const MultipleLayerSelect: FC<MultipleLayerSelectProps> = (props: MultipleLayerSelectProps) => {
  const { t } = useTranslation();
  const {
    options,
    onChange,
    placeholder = t('actions.search'),
    value,
    isClearable = false,
  } = props;
  const [isShowMultiSelect, setIsShowMultiSelect] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>('');
  const [matchedOptions, setMatchedOption] = useState<OptionItem[]>([]);
  const [sortedOptions, setSortedOptions] = useState<OptionItem[]>([]);
  const [selectedOption, setSelectedOption] = useState<OptionItem | undefined>();
  const [parentOptionsMap, setParentOptionsMap] = useState<HashArgs>({});
  const { isMobile, isDesktop } = useScreenInfos();

  const deepSortByLabel = useCallback((options: OptionItem[]): OptionItem[] => {
    const sortedOptions: OptionItem[] = options.map((option) => {
      if (option.children && option.children.length > 0) {
        const sortedChild = deepSortByLabel(option.children);
        return {
          ...option,
          children: sortedChild,
        };
      }
      return option;
    });
    return sortedOptions.sort(compareLabel);
  }, []);

  const setSelectedOptionById = useCallback(
    (id: OptionValue | undefined) => {
      if (!id) return;

      const selectedLayer = parentOptionsMap[id];
      if (selectedLayer && selectedLayer.children) {
        const option = selectedLayer.children.find((option: OptionItem) => option.id === id);
        setSelectedOption(option);
      } else {
        const option = sortedOptions.find((option) => option.id === id);
        setSelectedOption(option);
      }
    },
    [parentOptionsMap, sortedOptions]
  );

  useEffect(() => {
    setSelectedOptionById(value);
  }, [setSelectedOptionById, value]);

  useEffect(() => {
    const deepSortedOptions = deepSortByLabel(options);
    setSortedOptions(deepSortedOptions);
    setParentOptionsMap(createParentOptionsHashMap(deepSortedOptions));
  }, [deepSortByLabel, options]);

  const handleRemove = () => {
    setIsShowMultiSelect(false);
    setSearchText('');
    setSelectedOption(undefined);
    onChange(undefined);
  };

  const handleSelect = (option: OptionItem) => {
    setIsShowMultiSelect(false);
    setSelectedOption(option);
    onChange(option.id);
    setSearchText('');
  };

  const compareLabel = (targetOption: OptionItem, compareOption: OptionItem): number => {
    if (targetOption.label < compareOption.label) return -1;
    if (targetOption.label > compareOption.label) return 1;
    return 0;
  };

  const formatSelectedOptionByParent = (option: OptionItem): string => {
    if (parentOptionsMap) {
      const layer = parentOptionsMap[option.id];
      if (layer) {
        let layerLabel = layer.label;
        const parentLayer = parentOptionsMap[layer.id];

        if (parentLayer) {
          layerLabel = formatSelectedOptionByParent(layer);
        }

        return `${layerLabel} > ${option.label}`;
      }
    }

    return option.label;
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
    const filteredOptions = filterOptionsByText(value, sortedOptions);
    setMatchedOption(filteredOptions);
  };

  const handleShowSelect = () => {
    setIsShowMultiSelect(!isShowMultiSelect);
    setSearchText('');
  };

  const filterOptionsByText = (target: string, options: OptionItem[]): OptionItem[] => {
    if (!target) {
      return options;
    }

    const resultOptions: OptionItem[] = options.filter((option) => {
      if (option.children && option.children.length > 0) {
        const childMatch = filterOptionsByText(target, option.children);
        option.matchedChild = []; // remove previous search results
        if (childMatch.length > 0) {
          option.matchedChild = childMatch;
          return true;
        }
      }

      return option.label.toUpperCase().includes(target.toUpperCase());
    });
    return resultOptions;
  };

  return (
    <Box position='relative'>
      <Portal>
        <Box
          zIndex={isShowMultiSelect ? 99 : -99}
          position='fixed'
          inset={0}
          onClick={() => {
            setIsShowMultiSelect(!isShowMultiSelect);
          }}
          p={0}
          m={0}
        />
      </Portal>
      <HStack>
        <MultipleLayerSelectInputGroup
          searchText={searchText}
          placeholder={placeholder}
          isShowMultiSelect={isShowMultiSelect}
          selectedOption={selectedOption}
          handleRemove={handleRemove}
          handleSearch={handleSearch}
          handleShowSelect={handleShowSelect}
          formatSelectedOptionByParent={formatSelectedOptionByParent}
          handleIsShowMultiSelect={(value: boolean) => setIsShowMultiSelect(value)}
          isClearable={isClearable}
        />
      </HStack>
      {isDesktop && (
        <Box pos='absolute' top={10} insetX={0} zIndex={100}>
          <Collapse in={isShowMultiSelect} animateOpacity>
            <Box
              bg='neutral.0'
              mt={1}
              py={2}
              rounded='md'
              border='1px'
              borderColor={{
                base: 'transparent',
                md: 'neutral.200',
              }}
            >
              <MultipleLayerSelectOptionWrapper
                searchText={searchText}
                matchedOptions={matchedOptions}
                sortedOptions={sortedOptions}
                selectedOption={selectedOption}
                parentOptionsMap={parentOptionsMap}
                handleSelect={handleSelect}
              />
            </Box>
          </Collapse>
        </Box>
      )}
      {isMobile && (
        <ClosableModal
          isOpen={isShowMultiSelect}
          size={{ base: 'full', md: 'lg' }}
          scrollBehavior='inside'
          onClose={() => setIsShowMultiSelect(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody p={1} borderTop='1px' borderColor='neutral.300' bg='neutral.0'>
              <Flex>
                <Text mx='auto' fontWeight={700} my={3}>
                  設備
                </Text>
              </Flex>
              <HStack>
                <MultipleLayerSelectInputGroup
                  isModal
                  searchText={searchText}
                  placeholder={placeholder}
                  isShowMultiSelect={isShowMultiSelect}
                  selectedOption={selectedOption}
                  handleRemove={handleRemove}
                  handleSearch={handleSearch}
                  handleShowSelect={handleShowSelect}
                  formatSelectedOptionByParent={formatSelectedOptionByParent}
                  handleIsShowMultiSelect={(value: boolean) => setIsShowMultiSelect(value)}
                  isClearable={isClearable}
                />
              </HStack>
              <Divider mt={2} />
              <Box
                bg='neutral.0'
                mt={1}
                py={2}
                rounded='md'
                border='1px'
                borderColor={{
                  base: 'transparent',
                  md: 'neutral.200',
                }}
              >
                <MultipleLayerSelectOptionWrapper
                  searchText={searchText}
                  matchedOptions={matchedOptions}
                  sortedOptions={sortedOptions}
                  selectedOption={selectedOption}
                  parentOptionsMap={parentOptionsMap}
                  handleSelect={handleSelect}
                />
              </Box>
            </ModalBody>
          </ModalContent>
        </ClosableModal>
      )}
    </Box>
  );
};

type MultipleLayerSelectInputGroupProps = {
  isModal?: boolean;
  searchText: string;
  placeholder: string;
  isShowMultiSelect: boolean;
  selectedOption?: OptionItem;
  handleRemove: () => void;
  handleSearch: (value: string) => void;
  handleShowSelect: () => void;
  handleIsShowMultiSelect: (value: boolean) => void;
  formatSelectedOptionByParent: (option: OptionItem) => string;
  isClearable: boolean;
};

const MultipleLayerSelectInputGroup = (props: MultipleLayerSelectInputGroupProps) => {
  const {
    isModal,
    searchText,
    placeholder,
    isShowMultiSelect,
    selectedOption,
    handleRemove,
    handleSearch,
    handleShowSelect,
    handleIsShowMultiSelect,
    formatSelectedOptionByParent,
    isClearable,
  } = props;

  const textDirection = selectedOption ? 'rtl' : 'ltr';

  return (
    <>
      <InputGroup size='md'>
        <InputLeftElement pointerEvents='none'>
          <SearchIcon color='neutral.500' />
        </InputLeftElement>
        {selectedOption && !isModal ? (
          <Input
            pr='5rem'
            value={formatSelectedOptionByParent(selectedOption)}
            onClick={() => handleIsShowMultiSelect(true)}
            placeholder={placeholder}
            _placeholder={{ color: 'neutral.500' }}
            style={{
              textAlign: 'left',
              direction: textDirection,
            }}
          />
        ) : (
          <Input
            pr='5rem'
            value={searchText}
            onChange={(event) => handleSearch(event.target.value)}
            onClick={() => handleIsShowMultiSelect(true)}
            placeholder={placeholder}
            _placeholder={{ color: 'neutral.500' }}
            style={{
              textAlign: 'left',
              direction: textDirection,
            }}
          />
        )}

        <InputRightElement width='5rem' justifyContent='end'>
          <HStack spacing={0} py={0}>
            {selectedOption && isClearable && (
              <IconButton
                as='div'
                fontSize={16}
                variant='unstyled'
                display='flex'
                justifyContent='center'
                alignItems='center'
                aria-label='Clear'
                color='neutral.500'
                icon={<CloseIcon />}
                onClick={() => handleRemove()}
              />
            )}
            {!isModal && (
              <IconButton
                fontSize={24}
                variant='unstyled'
                display='flex'
                justifyContent='center'
                alignItems='center'
                borderLeft='1px'
                borderColor='neutral.300'
                borderRadius='none'
                height='40px'
                aria-label='Select layers'
                color={isShowMultiSelect ? 'primary.500' : 'neutral.500'}
                icon={isShowMultiSelect ? <ArrowUpIcon /> : <ArrowDownIcon />}
                onClick={() => handleShowSelect()}
              />
            )}
          </HStack>
        </InputRightElement>
      </InputGroup>
    </>
  );
};

type MultipleLayerSelectOptionWrapperProps = {
  searchText: string;
  matchedOptions: OptionItem[];
  sortedOptions: OptionItem[];
  selectedOption?: OptionItem;
  parentOptionsMap: HashArgs;
  handleSelect: (option: OptionItem) => void;
};
const MultipleLayerSelectOptionWrapper = (props: MultipleLayerSelectOptionWrapperProps) => {
  const {
    searchText,
    matchedOptions,
    sortedOptions,
    selectedOption,
    parentOptionsMap,
    handleSelect,
  } = props;

  const { t_errors } = useTranslation();

  return (
    <>
      {searchText ? (
        <Stack width='full' spacing='0px'>
          {matchedOptions.length > 0 ? (
            <MultipleLayerSearchOption
              searchText={searchText}
              onSelect={handleSelect}
              currentOptions={matchedOptions}
              selectedOption={selectedOption}
            />
          ) : (
            <Text>{t_errors('not-found')}</Text>
          )}
        </Stack>
      ) : (
        <Stack width='full' spacing='0px'>
          <MultipleLayerSelectOption
            onSelect={handleSelect}
            rootOptions={sortedOptions}
            defaultOption={selectedOption}
            parentOptionsMap={parentOptionsMap}
          />
        </Stack>
      )}
    </>
  );
};

export default MultipleLayerSelect;
